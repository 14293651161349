<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded>
    <v-card flat>
      <v-toolbar dense flat><span :style="[{'color': colores.primario}, {'font-size': '20px' }]"><b>REQUERIMIENTO</b></span></v-toolbar>
      <v-card-text class="pt-0">
        <v-row>
          <v-col cols="12" md="6">
            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="form_qr.fecha_requerimiento" label="FECHA DE RADICACIÓN" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker  locale="es-cn" v-model="form_qr.fecha_requerimiento" @input="menu = false"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-autocomplete v-model="items.objTipoQR" return-object v-on:input="input_tipo(items.objTipoQR)" :rules="[rules.requerido]" :items="items.tiporequerimientos" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="TIPO DE REQUERIMIENTO *"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" class="pr-1">
            <v-autocomplete v-model="form_qr.instancia_id" v-on:input="input_instancia(form_qr.instancia_id)" :rules="[rules.requerido]" :items="items.instanciarecepcion" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="INSTANCIA DE RECEPCIÓN *"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="showCanal">
          <v-col cols="12" md="6" class="pr-1">
            <v-autocomplete v-model="form_qr.canal_id" :rules="[rules.requerido]" :items="items.puntorecepcion" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="CANAL/PUNTO DE RECEPCIÓN *"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1" v-if="showAdmision">
            <v-autocomplete v-model="form_qr.admision_id" clearable :items="items.admisiones" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="ADMISIÓN"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" class="pr-1" v-if="showEnteControl">
            <v-autocomplete v-model="form_qr.entecontrol_id" :rules="[rules.requerido]" :items="items.entecontroles" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="ENTE DE CONTROL *"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-autocomplete v-model="form_duplicado.duplicado_id" v-on:input="input_duplicado(form_duplicado.duplicado_id)" :rules="[rules.requerido]" :items="items.tiposi" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="DUPLICADO"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" v-if="form_duplicado.duplicado_id===29" class="pr-1">
            <v-autocomplete v-model="form_duplicado.duplicadomotivo_id" :rules="[rules.requerido]" :items="items.motivoduplicados" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="MOTIVO DUPLICADO"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="2" v-if="form_duplicado.duplicado_id===29">
            <v-text-field v-model="form_duplicado.codigo" type="number" min="0" autocomplete="off" :rules="[rules.requerido]" label="QR INICIAL" :color="colores.primario"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-autocomplete v-model="form_duplicado.reincidente_id" :rules="[rules.requerido]" :items="items.tiposi" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="REINCIDENTE"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" v-if="showCanalOri">
            <v-autocomplete v-model="form_duplicado.canaoriginol_id" :rules="[rules.requerido]" :items="items.canales" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="CANAL QUE ORIGINÓ QR"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="items.showMarcacion">
          <v-col cols="12" align-self="center">
            <v-toolbar dense flat><span :style="[{'color': colores.primario}, {'font-size': '16px' }]"><b>MARCACIÓN</b></span></v-toolbar>
            <v-radio-group v-model="form_duplicado.marcacion_id" :rules="[rules.requerido]" hide-details dense>
              <div v-for="(item, index) in items_marcacion" :key="index">
              <v-radio :color="colores.primario" :label="item.descripcion" :value="item.id"></v-radio>
              <v-divider></v-divider>
              </div>
            </v-radio-group>
          </v-col>
        </v-row>
        <br v-if="items.showMarcacion">
        <v-row no-gutters>
          <v-col cols="12">
            <v-autocomplete v-model="items.objMotivoQR" v-on:input="input_motivo(items.objMotivoQR)" return-object :rules="[rules.requerido]" :items="items.motivoprincipales" :loading="loadingMotivo" item-text="descripcion" item-value="id" :color="colores.primario" label="MOTIVO PRINCIPAL *"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-autocomplete v-model="items.objSubMotivoQR" v-on:input="input_submotivo(items.objSubMotivoQR)" :rules="[rules.requerido]" return-object :items="items_motivosqr" :loading="loadingMotivoQR" item-text="descripcion" item-value="id" :color="colores.primario" label="MOTIVO *"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="descripcionqr">
          <v-col cols="12">
            <span :style="[{'color': colores.primario}, {'font-size': 10 }]">DESCRIPCIÓN MOTIVO</span>
            <v-card-text style="white-space:pre-wrap;" class="pl-0 pt-0">
              <span>{{ descripcionqr }}</span>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="showQuejaExpres">
          <v-col cols="12" md="6" class="pr-1">
            <v-autocomplete v-model="form_qr.quejaexpres_id" :rules="[rules.requerido]" :items="items.tiposi" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="QUEJA EXPRÉS *"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-textarea v-model="form_qr.descripcion" :rules="[rules.requerido]" :color="colores.primario" maxlength="4500" counter="4500" label="DESCRIPCIÓN DE LA QR (ESCRIBIR EL MOTIVO Y DETALLE DE LA QR) *" rows="6"></v-textarea>
          </v-col>
        </v-row>
        <br>
        <v-row no-gutters>
          <v-col cols="12" class="text-left">
            <uploadFile :session="session" :keyform="form_qr.keyform" :items_archivos="form_files.items_archivos" :urlroute="'upload_cargar_nuevo'" :tipo="'GESTION'" :colores="colores" @refresh_archivos="refrescar_archivos"></uploadFile>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-sheet>

  <br>
</div>
</template>

<script>
import recListado from '@/js/rec_listado.js'
// import uploadFile from '@/components/widgets/uploadComponent.vue'

export default {
  name: 'qrComponent',
  components: {
    uploadFile: () => import('@/components/widgets/uploadComponent.vue')
  },
  mixins: [recListado],
  props: ['colores', 'session', 'items', 'loadingData', 'form_qr', 'form_duplicado', 'rules', 'form_files'],
  data: () => ({
    loadingMotivo: false,
    loadingMotivoQR: false,
    items_motivosqr: [],
    items_marcacion: [],
    descripcionqr: '',
    showQuejaExpres: false,
    showCanal: false,
    showCanalOri: false,
    showAdmision: false,
    showEnteControl: false,
    menu: false
  }),
  watch: {
    items: {
      immediate: true,
      handler (newVal, oldVal) {
        this.items.marcaciones.forEach(element => {
          if (element.descripcion === 'Si La Queja Fue Cerrada Por Estar Repetida En Smartsupervision' || element.id === 488) {
            this.items_marcacion.push(element)
          }
        })
      }
    }
  },
  methods: {
    input_tipo (pObj) {
      this.form_qr.motivo_id = 0
      this.form_qr.submotivo_id = 0
      this.items.objMotivoQR = null
      this.items.objSubMotivoQR = null
      this.form_qr.area = null
      this.form_qr.tipo_id = pObj.id
      this.form_qr.quejaexpres_id = null
      this.showQuejaExpres = false
      this.showCanalOri = false

      if (pObj.id === 76 || pObj.id === 77) {
        this.showQuejaExpres = true
        this.showCanalOri = true
      }
      this.rec_listado_motivo(pObj.secundario_id)
    },
    input_duplicado (pDuplicadoId) {
      this.items.showMarcacion = false
      this.form_duplicado.duplicadomotivo_id = null
      this.form_duplicado.codigo = 0
      if (pDuplicadoId === 29) {
        this.items.showMarcacion = true
      } else {
        this.form_duplicado.marcacion_id = null
      }
    },
    input_motivo (pMotivo) {
      this.form_qr.area = (pMotivo.evento === null) ? null : pMotivo.evento.area_id
      this.form_qr.motivo_id = pMotivo.id
      this.form_qr.submotivo_id = 0
      this.items.objSubMotivoQR = null
      this.descripcionqr = ''
      this.rec_listado_submotivo(pMotivo.id)
    },
    input_submotivo (pObj) {
      this.form_qr.submotivo_id = pObj.id
      this.form_qr.diasext = (pObj.accion === null) ? 0 : pObj.accion.diasext
      this.form_qr.diasint = (pObj.accion === null) ? 0 : pObj.accion.diasint
      this.descripcionqr = pObj.opcion1
    },
    input_instancia (pInstancia) {
      this.showCanal = false
      this.showAdmision = false
      this.showEnteControl = false
      this.form_qr.canal_id = null
      this.form_qr.admision_id = null
      this.form_qr.entecontrol_id = null
      if (pInstancia === 81) { // ENTIDAD VIGILADA
        this.form_qr.admision_id = 107
        this.showCanal = true
      } else if (pInstancia === 82) { // DEFENSOR DEL CONSUMIDOR
        this.showAdmision = true
        this.showCanal = true
      } else if (pInstancia === 83) { // DEFENSOR DEL CONSUMIDOR
        this.showEnteControl = true
        this.showCanal = true
        this.form_qr.admision_id = 107
      } else if (pInstancia === 80) { // DEFENSOR DEL CONSUMIDOR
        this.showCanal = true
        this.form_qr.admision_id = 107
      }
    },
    refrescar_archivos (data) {
      for (let i = 0; i < data.length; i++) {
        this.form_files.items_archivos.push(data[i])
      }
    }

  }
}
</script>
